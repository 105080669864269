import {parse} from "date-fns";
import {User, UserRaw} from "../interfaces/user";

export class UserFactory {
  public static fromRaw(u: UserRaw): User {
    return {
      ...u,
      birthday: u.birthday ? parse(u.birthday, 'yyyy-MM-dd', new Date()) : undefined,
    };
  }
}
